export const workshopData = [
    {
        name: 'Dr. Phung Tran Huy Nhat',
        image: require('../../images/body/workshop/Phung-Tran-Huy-Nhat.png'),
        uni: 'AstraZeneca',
        email: 'tranhuynhat.phung@astrazeneca.com',
        slide: 'Clinical application of Al in echocardiography for heart failure detection Vietnam',
    },
    {
        name: 'Assc. Prof. Le Thi Lan',
        image: require('../../images/body/workshop/Le-Thi-Lan.png'),
        uni: 'Hanoi University of Science & Technology',
        email: 'lan.lethi1@hust.edu.vn',
        slide: 'Recent Advances in Segmentation of Ovarian Tumors from Ultrasound Images',
        desc: 'Le Thi Lan is an Associte Professor at the School of Electronics and Telecommunications, Hanoi University of Science and Technology (HUST), Vietnam, and a Researcher at the Computer Vision Department, MICA. She earned her Ph.D. in Computer Vision from INRIA Sophia Antipolis, France, in 2009, after completing a Master\'s in Signal Processing and Multimedia, and a B.A. in Information Technology from Hanoi University of Science and Technology. She has served as the Head of the Computer Vision Department at MICA since 2011. Her research interests focus on computer vision and related technologies.'
    },
    {
        name: 'Dr. Ha Thi Thanh Huong',
        image: require('../../images/body/workshop/Ha-Thi-Thanh-Huong.png'),
        uni: 'VNU-HCMC International University',
        email: 'htthuong@hcmiu.edu.vn',
        slide: "Exploring Dementia and Alzheimer's diseases with a multifaceted approach to address care shortcomings in Vietnam",
        desc: 'Dr. Ha Thi Thanh Huong serves as a lecturer at the School of Biomedical Engineering at International University, Vietnam National University, and is the Head of the Brain Health Lab. During her undergraduate studies, she investigated molecular markers for cervical cancer diagnostics. Following graduation, she contributed to research at the Oxford University Clinical Research Unit, focusing on HIV-associated neurological disorders (HAND). Her PhD research at Stanford University delved into the molecular systems associated with autism, as well as the cellular and network deficits underlying epilepsy. This work included the development of gene delivery technologies and brain tissue culture platforms aimed at facilitating drug discovery. Her research interests focus on system neuroscience, neuroengineering, cognitive science,  and the early diagnosis and intervention strategies for Alzheimer\'s disease and various mental health disorders.'
    },
    {
        name: 'Dr. Le Duc Trong',
        image: require('../../images/body/workshop/Le-Duc-Trong.png'),
        uni: 'VNU University of Engineering & Technology',
        email: 'trongld@vnu.edu.vn',
        slide: 'A multitask approach for lung cancer diagonosis using bronchoscopy images',
    },
    {
        name: 'Assoc. Prof. Nguyen Phi Le',
        image: require('../../images/body/workshop/Nguyen-Phi-Le.png'),
        uni: 'Hanoi University of Science & Technology',
        email: 'lenp@soict.hust.edu.vn',
        slide: 'VAIPE: Protective healthcare monitoring and Supporting System for Vietnamese',
        desc: 'Assc. Prof. Nguyen Phi Le received her Bachelor of Information Technology and Master of Informatics from the University of Tokyo, Japan in 2007 and 2010, respectively. She started working at Hanoi University of Science and Technology (HUST) as a lecturer in 2010. She then received her PhD in Informatics from SOKENDAI University, National Institute of Informatics, Japan in 2019. Currently, she is the Executive Director of the International Research Center for Artificial Intelligence (BKAI), and the leader of a research group of more than 30 talented students, masters and PhD students at HUST. Her main research interests are Communication Networks, Applied Artificial Intelligence, and Data Science. '
    },
    {
        name: 'Dr. Pham Huy Hieu',
        image: require('../../images/body/workshop/Pham-Huy-Hieu.png'),
        uni: 'VinUniversity',
        email: 'hieu.ph@vinuni.edu.vn',
        slide: 'VAIPE: Protective healthcare monitoring and Supporting System for Vietnamese',
        desc: 'Assist. Prof. Hieu Pham is currently an Assistant Professor at the College of Engineering and Computer Science (CECS), VinUniversity. He is also serving as the Scientific Director of Entrepreneurship Lab at VInUniserity. Dr. Hieu received his Ph.D. in Computer Science from the Toulouse Computer Science Research Institute (IRIT), University of Toulouse, France, in 2019. Previously, he earned the Degree of Engineer from Hanoi University of Science and Technology (HUST), Vietnam, in 2016. Before joining VinUniversity, Dr. Hieu worked at Vingroup Big Data Institute as Head of the Fundamental Research Team. His research interests include Computer Vision, Machine Learning, Medical Image Analysis, and their applications in Smart Healthcare.'
    },
    {
        name: 'Dr. Do Thanh Ha',
        image: require('../../images/body/workshop/Do-Thanh-Ha.png'),
        uni: 'VNU University of Science',
        email: 'dothanhha@hus.edu.vn',
        slide: 'Develop the deep learning models in classifying and diagnosising of thyroid cancer based on microscopic images',
        desc: ''
    },
    {
        name: 'Dr. Nguyen Thi Hoa Huyen',
        image: require('../../images/body/workshop/Nguyen-Thi-Hoa-Huyen.jpeg'),
        uni: 'VinUniversity',
        email: 'huyen.nth@vinuni.edu.vn',
        slide: 'Cancer Wellness Program Vietnam',
        desc: ''
    },
]