import React from 'react';
import Underline from '../../Header/Underline';
import './workshop.sass';
import { workshopData } from './data';
import Iframe from 'react-iframe';
import mailIcon from '../../images/body/workshop/icon/mail.svg';
import slideIcon from '../../images/body/workshop/icon/slide.svg';

const Workshop = () => {
  return (
    <div>
      <div className='block_title'>
        <p>Workshop</p>
        <Underline />
      </div>
      <div className='workshop'>
        <div className='text-center subtitle'>2024 SMART HEALTH WORKSHOP</div>
        <p className='sub-content'>
          Explore latest research in Artificial Intelligence (AI), Data Science
          and their applications in Smart Health in Vietnam with leading
          experts.
        </p>
        <Iframe
          width='100%'
          height='600px'
          className='workshop_video'
          url='https://www.youtube.com/embed/dDxnDHXchSI?si=mZip08TDfBc8Y1YM'
        ></Iframe>
        <div className='text-center subtitle'>Best Experienced Speakers</div>
        <p className='sub-content'>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur */}
        </p>
        <div className='workshop_member'>
          {workshopData.map((item, index) => (
            <div key={index} className='workshop_member_item'>
              <div className='workshop_member_item_main'>
                <img
                  className='workshop_member_avatar'
                  src={item.image}
                  alt=''
                />
                {item.desc && <div className='des_person'>{item.desc}</div>}
              </div>
              <div className='person_name'>{item.name}</div>
              <p className='university_name'>{item.uni}</p>
              <p className='email'>
                <img src={mailIcon} alt='mail-icon' height={20} width={20} />
                {item.email}
              </p>
              <p className='slide'>
                <img src={slideIcon} alt='slide-icon' height={20} width={20} />
                {item.slide}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Workshop;
